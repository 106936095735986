<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="item in availableTabs"
          :key="item.title"
        >
          {{ item.title }}
        </v-tab>
      </template>
      <v-tabs-items class="setting__page__tabs" v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="item.title"
          :eager="true"
        >
          <v-card class="px-5 py-6 mt-4" v-if="item.hasPermission">
            <component
              :is="item.name"
              @toggleServiceModel="toggleServiceModel"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <ServiceModel
      :dialogState="serviceDialogState"
      :templateDate="templateDate"
      :hasUpdatePermission="hasUpdatePermission('servicesSettings')"
      @toggleServiceModel="toggleServiceModel"
      @updateServiceSettingss="updateServiceSettingss"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'FinantionalSettings',
  components: {
    Services: () =>
      import('@/components/profile/env-setting-comps/Services.vue'),
    ServiceModel: () =>
      import('@/components/profile/env-setting-comps/ServiceModel.vue'),
    TermsAndConditions: () =>
      import('@/components/profile/env-setting-comps/TermsAndConditions.vue')
  },
  data: () => ({
    tab: 0,
    serviceDialogState: false,
    templateDate: {}
  }),
  watch: {
    currentEnv() {
      this.env = { ...this.currentEnv }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    isOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    availableTabs() {
      return [
        {
          title: 'إعدادات الخدمات',
          name: 'Services',
          hasPermission: !!this.isOwner || this.hasListPermission('financial')
        },
        {
          title: 'الشروط والأحكام',
          name: 'TermsAndConditions',
          hasPermission: !!this.isOwner || this.hasListPermission('financial')
        }
      ]
    }
  },
  methods: {
    ...mapActions('accountState', ['updateEnvSettings', 'updateCurrentEnv']),

    hasListPermission(module) {
      return !!this.$hasPermission('users', module, 'list')
    },
    hasUpdatePermission(module) {
      return !!this.$hasPermission('users', module, 'update')
    },
    toggleServiceModel(state) {
      this.serviceDialogState = state.dialogState
      this.templateDate = state.template
    },
    updateServiceSettingss(newServices) {
      const services = this.currentEnv.services
      const updatedServices = services.map((service) => {
        if (service.code === newServices.code) {
          return newServices
        }
        return service
      })

      this.updateCurrentEnv({ services: updatedServices })
    }
  }
}
</script>
